/* You can add global styles to this file, and also import other style files */
$primary: #000066;
$myborder: #ababab;
$input-border-color: $myborder;
$myplaceholder: #616466;


@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxweb-book-woff-data.woff) format("woff");
  font-weight: 400
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxweb-bookitalic-woff-data.woff) format("woff");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxsub-medium-woff-data.woff) format("woff");
  font-weight: 500
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxsub-mediumitalic-woff-data.woff) format("woff");
  font-weight: 500;
  font-style: italic
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font//circularxxweb-bold-woff-data.woff) format("woff");
  font-weight: 700
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxweb-bolditalic-woff-data.woff) format("woff");
  font-weight: 700;
  font-style: italic
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxweb-black-woff-data.woff) format("woff");
  font-weight: 800
}

@font-face {
  font-family: "Circular Pro";
  src: url(assets/font/circularxxweb-blackitalic-woff-data.woff) format("woff");
  font-weight: 800;
  font-style: italic
}

$font-family-base: "Circular Pro", sans-serif;

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import "~@ng-select/ng-select/themes/default.theme.css";

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: $myplaceholder;
}

.ng-select.ng-select-opened>.ng-select-container {
  border-color: $myborder;
}

.ng-select .ng-select-container {
  border-color: $myborder;
}

.mw1360 {
  max-width: 1360px;
}

.mw600 {
  max-width: 600px;
}

.mw300 {
  max-width: 300px;
}

.mw150 {
  max-width: 150px;
}

.form-check-input[type=checkbox] {
  border-color: $myborder;
}

.form-check-input[type=radio] {
  border-color: $myborder;
}

.mybox {
  border-radius: 15px;
  background: var(--Neutrals-50, #FFF);
  box-shadow: 0px 6px 24px 0px rgba(140, 149, 159, 0.20), 0px 1px 4px 0px rgba(140, 149, 159, 0.30);
}

.vam {
  vertical-align: middle;
}

.clickable-text {
  cursor: pointer;
  color: $primary;
  /* Change cursor to pointer on hover to indicate clickable */
}
